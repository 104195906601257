(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("js2flowchart", [], factory);
	else if(typeof exports === 'object')
		exports["js2flowchart"] = factory();
	else
		root["js2flowchart"] = factory();
})((typeof window !== 'undefined' ? window : this), () => {
return 